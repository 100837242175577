import React, { useState, useRef, useEffect } from 'react';
import './ScheduleButton.scss';

const ScheduleButton: React.FC = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleScheduleMeeting = (url: string) => {
    const userConfirmed = window.confirm("Are You Sure You Would Like To Leave This Site?");
    if (userConfirmed) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <div className="schedule-button-container" ref={dropdownRef}>
      <button className="schedule-button" onClick={toggleDropdown}>
        <h1>Schedule a Meeting</h1><span className="separator">|</span> <span className="dropdown-arrow">▼</span>
      </button>
      {showDropdown && (
        <div className="dropdown-menu">
          <div
            className="dropdown-item"
            onClick={() => handleScheduleMeeting('https://calendly.com/sifs-jarrett')}
          >
            Jarrett Sharp
          </div>
          <div
            className="dropdown-item"
            id='emmett'
            onClick={() => handleScheduleMeeting('https://calendly.com/sifs-emmett')}
          >
            Emmett Fehlau-Barton
          </div>
        </div>
      )}
    </div>
  );
};

export default ScheduleButton;