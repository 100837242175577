import React, { useState, useCallback, useEffect } from 'react';
import './JarrettBio.scss';
import Founder from '../../../../images/founder.png';
import FounderMobile from '../../../../images/founder_card.png';
import LinkedIn_Logo from '../../../../images/linkedin_icon.png';
import Email_Logo from '../../../../images/email_icon.png';
import Phone_Logo from '../../../../images/phone.png';
import Mountains from '../../../../images/mountains.png';

const JarrettBio: React.FC = () => {
  const [currentDescIndex, setCurrentDescIndex] = useState(0);
  const [resetTimer, setResetTimer] = useState(false);
  const [smallerView, setSmallerView] = useState(window.innerWidth <= 800);

  const descriptions = [
    <>In 2019, Jarrett commenced his professional journey with a role at a leading financial planning firm in the Boulder and 
    Denver area. His dedication to the field was solidified by earning a degree in personal financial planning from the University of 
    Colorado Boulder's Leeds School of Business in 2020. Jarrett's passion for finance ignited at the age of 16, propelling him to 
    delve into the intricacies of the stock market and amass a comprehensive understanding of personal finances.</>,
    
    <>Throughout his undergraduate years, Jarrett demonstrated leadership as the Vice President of the Leeds School of Business Financial 
    Planning Association and actively engaged in the Leeds Investment Club. Transitioning into the professional realm, he obtained his 
    Series 65 license in March 2021, marking the inception of his direct involvement with clients. His responsibilities encompassed 
    formulating financial plans and delivering investment advice. Demonstrating competence, Jarrett earned a promotion to the 
    position of Chief Financial Officer before reaching the age of 22, honing his accounting skills along the way.</>,

    <>In the course of his professional journey, Jarrett seized the valuable opportunity to collaborate with multiple CERTIFIED FINANCIAL 
    PLANNER™ (CFP®) professionals. His approach was marked by a keen curiosity and an ardent desire to learn, enabling him to absorb and 
    integrate the wealth of knowledge imparted by these seasoned professionals. This engagement not only highlighted Jarrett's commitment 
    to continuous learning but also provided him with a diverse and comprehensive understanding of the intricacies within the realm of 
    financial planning.</>,

    <>In July 2023, propelled by a pursuit of further education, Jarrett made the strategic decision to return to school and pursue an 
    MBA at the University of Southern California's Marshall School of Business. This academic endeavor laid the groundwork for his vision. 
    In January 2024, he transformed his aspirations into reality by founding Sharper Image Financial Services, LLC. With an unwavering 
    commitment to delivering outstanding service to clients, Jarrett aims to bring meaningful value to those who collaborate with him, 
    embodying his dedication to the financial planning landscape.</>
  ];

  const handleExternalLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, url: string) => {
    event.preventDefault();
    const userConfirmed = window.confirm("Are You Sure You Would Like To Leave This Site?");
    if (userConfirmed) {
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'ArrowRight') {
      setCurrentDescIndex((prevIndex) => (prevIndex + 1) % descriptions.length);
      setResetTimer(!resetTimer);
    } else if (event.key === 'ArrowLeft') {
      setCurrentDescIndex((prevIndex) => (prevIndex - 1 + descriptions.length) % descriptions.length);
      setResetTimer(!resetTimer);
    }
  }, [descriptions.length, resetTimer]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDescIndex((prevIndex) => (prevIndex + 1) % descriptions.length);
    }, 30000);

    return () => clearInterval(timer);
  }, [resetTimer, descriptions.length]);

  const handleTouchStart = (e: React.TouchEvent) => {
    const touchStartX = e.touches[0].clientX;

    const handleTouchEnd = (event: TouchEvent) => {
      const touchEndX = event.changedTouches[0].clientX;
      if (touchStartX - touchEndX > 50) {
        setCurrentDescIndex((prevIndex) => (prevIndex + 1) % descriptions.length);
        setResetTimer(!resetTimer);
      } else if (touchEndX - touchStartX > 50) {
        setCurrentDescIndex((prevIndex) => (prevIndex - 1 + descriptions.length) % descriptions.length);
        setResetTimer(!resetTimer);
      }
      document.removeEventListener('touchend', handleTouchEnd);
    };

    document.addEventListener('touchend', handleTouchEnd);
  };

  const handleDotClick = (index: number) => {
    setCurrentDescIndex(index);
    setResetTimer(!resetTimer);
  };

  useEffect(() => {
    const handleResize = () => {
      setSmallerView(window.innerWidth <= 800);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="jb_about-container">
      <title>Jarrett Sharp | Sharper Image Financial Services</title>
      <div className="jb_image-container">
        <img
          className={smallerView ? 'jb_mobile-foundationImg' : 'jb_founderImg'}
          src={smallerView ? FounderMobile : Founder}
          alt="Founder of SIFS"
        />
      </div>
      <div className="jb_founderInfo">
        <div className="jb_founderDetails">
          <h1 className="jb_founderName">Jarrett Sharp</h1>
          <h2 className="jb_founderTitle">Owner & Financial Advisor</h2>
        </div>
        {smallerView && (
          <div className="jb_social-icons">
            <a href="https://www.linkedin.com/in/jarrettisharp/"
              onClick={(e) => handleExternalLinkClick(e,"https://www.linkedin.com/in/jarrettisharp/")}>
              <img src={LinkedIn_Logo} alt="LinkedIn" className="jb_social-icon" id="jb_linkedIn" />
            </a>
            <a href="mailto:jarrett@sharperimagefinancial.com">
              <img src={Email_Logo} alt="Email" className="jb_social-icon" id="jb_email" />
            </a>
            <a href="Tel: +1(818)489-7628">
              <img src={Phone_Logo} alt="Phone Number" className="jb_social-icon" id="jb_phone" />
            </a>
          </div>
        )}
        <div className="jb_founderDescription">
          <div className="jb_description-content" onTouchStart={handleTouchStart}>
            <p>{descriptions[currentDescIndex]}</p>
            <div className="jb_indicator-container">
              {descriptions.map((_, index) => (
                <span
                  key={index}
                  className={`jb_indicator ${index === currentDescIndex ? 'active' : ''}`}
                  onClick={() => handleDotClick(index)}
                ></span>
              ))}
            </div>
          </div>
        </div>
        {!smallerView && (
          <div className="jb_social-icons">
            <a href="https://www.linkedin.com/in/jarrettisharp/"
              onClick={(e) => handleExternalLinkClick(e,"https://www.linkedin.com/in/jarrettisharp/")}>
              <img src={LinkedIn_Logo} alt="LinkedIn" className="jb_social-icon" id="jb_linkedIn" />
            </a>
            <a href="mailto:jarrett@sharperimagefinancial.com">
              <img src={Email_Logo} alt="Email" className="jb_social-icon" id="jb_email" />
            </a>
            <a href="Tel: +1(818)489-7628">
              <img src={Phone_Logo} alt="Phone Number" className="jb_social-icon" id="jb_phone" />
            </a>
          </div>
        )}
      </div>
      <img className="jb_mountainImg" src={Mountains} alt="backdrop" />
    </div>
  );
};

export default JarrettBio;