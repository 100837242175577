import React, { useState, useRef, useEffect, useCallback } from 'react';
import './Home.scss';

import Mountains from '../../images/mountains.png';
import Founder from '../../images/founder_card.png';
import Emmett from '../../images/emmett_thumbnail.png';
import ProsperImage from '../../images/thumbnail_3.jpg';

const Home: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const thumbnailsContainerRef = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const sliderItems = [
    {
      id: "strategic",
      imageUrl: 'https://images.unsplash.com/photo-1600104146011-ad1a8571f161?q=80&w=2950&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      title: 'Be Strategic.',
      content: 'At Sharper Image Financial, we believe in being strategic with your financial choices, helping craft plans tailored to your unique goals and circumstances.'
    },
    {
      id: "prosper",
      imageUrl: ProsperImage,
      title: 'Live Prosperously.',
      content: 'Our tailored financial strategies aim not just for stability, but for the prosperity that allows our clients to work towards achieving their dreams and aspirations.'
    },
    {
      id: "diligent",
      imageUrl: 'https://images.pexels.com/photos/6266283/pexels-photo-6266283.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Save Diligently.',
      content: 'Through meticulous planning and disciplined saving, Sharper Image Financial helps you build a robust financial foundation for a secure future.'
    },

  ];

  const resetInterval = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % sliderItems.length);
    }, 10000);
  }, [sliderItems.length]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        setActiveIndex((prevIndex) => (prevIndex + 1) % sliderItems.length);
        resetInterval();
      } else if (event.key === 'ArrowLeft') {
        setActiveIndex((prevIndex) => (prevIndex - 1 + sliderItems.length) % sliderItems.length);
        resetInterval();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [sliderItems.length, resetInterval]);

  useEffect(() => {
    resetInterval();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [resetInterval]);

  const smoothScrollTo = useCallback((element: HTMLElement, targetScrollLeft: number, duration: number) => {
    const startScrollLeft = element.scrollLeft;
    const startTime = performance.now();

    const animateScroll = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      const scrollProgress = Math.min(1, elapsedTime / duration);
      const easedProgress = easeInOutQuad(scrollProgress);
      const scrollLeft = startScrollLeft + (targetScrollLeft - startScrollLeft) * easedProgress;
      element.scrollLeft = scrollLeft;

      if (elapsedTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  }, []);

  const easeInOutQuad = (t: number) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  };

  useEffect(() => {
    const container = thumbnailsContainerRef.current;
    if (container) {
      const thumbnailElement = container.children[activeIndex] as HTMLElement;
      const containerWidth = container.clientWidth;
      const thumbnailWidth = thumbnailElement.clientWidth;
      const targetScrollLeft = thumbnailElement.offsetLeft - (containerWidth - thumbnailWidth) / 2;
      smoothScrollTo(container, targetScrollLeft, 600);
    }
  }, [activeIndex, smoothScrollTo]);

  const handleThumbnailClick = (index: number) => {
    setActiveIndex(index);
    resetInterval();
  };

  return (
    <div className='homePageContainer'>
      <title>Home | Sharper Image Financial Services</title>
      <div className="homeSlider">
        <div className="homeList">
          {sliderItems.map((item, index) => (
            <div
                key={index}
                className={`homeSliderItem ${index === activeIndex ? 'active' : ''} ${item.id === 'prosper' ? 'prosperImage' : ''}`}
            >
                <img src={item.imageUrl} alt="" />
                <div className={`sliderItemContent ${item.id === 'prosper' ? 'rightAligned' : item.id === 'diligent' ? 'centerAligned' : ''}`}>
                    <h2 id={item.id}>{item.title}</h2>
                    <p>{item.content}</p>
                </div>
            </div>
          ))}
        </div>
      </div>
      <div className="thumbnails" ref={thumbnailsContainerRef}>
        {sliderItems.map((_, index) => (
          <div key={index} className={`thumbnailItem ${index === activeIndex ? 'active' : ''}`} onClick={() => handleThumbnailClick(index)}>
            <div className="circle" />
          </div>
        ))}
      </div>
      <div className="aboutUs">
        <img className="mountainImg" src={Mountains} alt="backdrop" />
        <div className="vertical-line"></div>
        <div className="aboutUsContent">
          <h2>About Us</h2>
          <p className='aboutParagraph'>
            Our objective is to furnish you with a precise and vivid portrayal of your financial landscape, bringing <i>sharpness</i> and <i>clarity</i> to your finances, helping ensure 
            comprehensive understanding and informed decision-making.
          </p>
        </div>
        <div className="vertical-line"></div>
        <div className="aboutUsContent">
          <h2>Our Team</h2>
          <div className="membersContainer">
            <div className="team">
              <a href="/about/Jarrett_Sharp"><img src={Founder} alt="Founder of SIFS" /></a> 
              <h1>Jarrett Sharp</h1>
            </div>
            <div className="team">
              <a href="/about/Emmett_Fehlau-Barton"><img src={Emmett} alt="Financial Consultant, Emmett" /></a> 
              <h1>Emmett Fehlau-Barton</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;