import React, { useState, useEffect } from 'react';
import './Navbar.scss';
import Scheduler from '../Scheduler/ScheduleButton';
import HamMenu from '../HamburgerMenu/HamburgerMenu';
import schwab_logo from '../../images/schwab.png';
import rightCapital_logo from '../../images/rc-symbol.png';
import SIFS_logo from '../../images/SIFS_logo_white.png';

const Navbar: React.FC = () => {
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

  const handleExternalLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, url: string) => {
    event.preventDefault();
    const userConfirmed = window.confirm("Are You Sure You Would Like To Leave This Site?");
    if (userConfirmed) {
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };
  

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 790);
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (isSmallScreen) {
    return (
      <div>
        <div className="small-navbar-container">
          <HamMenu />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="navbar-container">
        <div className="navbar-left">
          <Scheduler />
          <a 
            href="https://client.schwab.com/Areas/Access/Login?&kc=y&sim=y"
            onClick={(e) => handleExternalLinkClick(e, "https://client.schwab.com/Areas/Access/Login?&kc=y&sim=y")}
          >
            <img className='external_Btn' src={schwab_logo} alt="Go To Schwab Button" />
          </a>
          <a 
            href="https://app.rightcapital.com/account/login"
            onClick={(e) => handleExternalLinkClick(e, "https://app.rightcapital.com/account/login")}
          >
            <img className='external_Btn' src={rightCapital_logo} alt="Go To Right Capital Button" />
          </a>

        </div>

        <div className="navbar-middle">
          <a className="company-name" href="/">
            <img className='coLogo' src={SIFS_logo} alt="Company Logo" />
          </a>
        </div>

        <div className="navbar-right">
          <ul className="menu__box">
            <li>
              <a className="menu__item" id='home' href="/">HOME</a>
            </li>
            <li className="dropdown">
              <a className="menu__item" href="/about">ABOUT</a>
              <ul className="dropdown-content">
                <li><a id='dropLink' href="/about/Jarrett_Sharp">Jarrett S.</a></li>
                <li><a id='dropLink' href="/about/Emmett_Fehlau-Barton">Emmett F.</a></li>
              </ul>
            </li>
            <li>
              <a className="menu__item" href="/services">SERVICES</a>
            </li>
            <li>
              <a className="menu__item" id='insight' href="/insight">INSIGHT</a>
            </li>
            <li className="dropdown">
              <a className="menu__item" href="/disclosures">DISCLOSURES</a>
              <ul className="dropdown-content">
                <li><a id='dropLink' href={require("../../documents/Form ADV Part 2A.pdf")} target="_blank" rel="noopener noreferrer">Form ADV Part 2A</a></li>
                <li><a id='dropLink' href={require("../../documents/SIFS_Privacy_Policy.pdf")} target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
