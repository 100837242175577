import React from 'react';
import './About.scss';
import Jarrett_Card from '../../images/founder_card.png';
import Emmett_Card from '../../images/emmett_thumbnail.png';
import LinkedIn_Logo from '../../images/linkedin_icon.png';
import Email_Logo from '../../images/email_icon.png';
import Phone_Logo from '../../images/phone.png';
import Mountains from '../../images/mountains.png';

type TeamMember = {
  name: string;
  role: string;
  id: string;
  imageUrl: string;
  link: string;
  linkedIn: string;
  email: string;
  phone: string;
};

const teamMembers: TeamMember[] = [
  {
    name: 'Jarrett Sharp',
    role: 'Owner & Financial Advisor',
    id: 'Jarrett',
    imageUrl: Jarrett_Card,
    link: '/about/Jarrett_Sharp',
    linkedIn: 'https://www.linkedin.com/in/jarrettisharp/',
    email: 'mailto:jarrett@sharperimagefinancial.com',
    phone: 'Tel: +1(818)489-7628',
  },
  {
    name: 'Emmett Fehlau-Barton',
    role: 'Financial Planner',
    id: 'Emmett',
    imageUrl: Emmett_Card,
    link: '/about/Emmett_Fehlau-Barton',
    linkedIn: 'https://www.linkedin.com/in/emmettfehlaubarton/',
    email: 'mailto:emmett@sharperimagefinancial.com',
    phone: "Tel: +1(720)530-5687",
  },
];

const About: React.FC = () => {
  const handleLinkedInClick = (url: string) => {
    const userConfirmed = window.confirm("Are You Sure You Would Like To Leave This Site?");
    if (userConfirmed) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className='about-page'>
      <title className="about-title">About | Sharper Image Financial Services</title>
      <div className="about-container">
        <h1 className="pageTitle">Meet The Team</h1>
        <h2 className='team_desc'>At Sharper Image Financial Services, our team of dedicated professionals is committed to helping guide you toward financial success. 
          With a blend of experience and innovation, we bring <i className='about_italics'>sharpness</i> and <i className='about_italics'>clarity</i> to your finances, providing personalized strategies tailored to your unique needs. 
          Together, we'll help you work towards achieving your financial goals with confidence and clarity.
        </h2>
        <div className="about-vertical-line" id='about'></div>
        <div className="team-cards">
          {teamMembers.map((member, index) => (
            <a href={member.link} className="team-card" key={index}>
              <img src={member.imageUrl} alt={member.name} className="team-image" id={member.id} />
              <h2 id={member.id} className="team-name">{member.name}</h2>
              <h3 className="team-role">{member.role}</h3>
              <div className="social-icons">
                <img
                  src={LinkedIn_Logo}
                  alt="LinkedIn"
                  className="b_social-icon"
                  id='b_linkedIn'
                  onClick={(e) => {
                    e.preventDefault();
                    handleLinkedInClick(member.linkedIn);
                  }}
                />
                <a href={member.email}>
                  <img src={Email_Logo} alt="Email" className="b_social-icon" id='b_email' />
                </a>
                <a href={member.phone}>
                  <img src={Phone_Logo} alt="Phone Number" className="b_social-icon" id='b_phone' />
                </a>
              </div>
            </a>
          ))}
        </div>
      </div>
      <img className="about_mountainImg" src={Mountains} alt="backdrop" />
    </div>
  );
};

export default About;