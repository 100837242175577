import React from 'react';
import './Footer.scss';
import BrokerCheckLogo from '../../images/brokercheck-finra-logo.png';
import Arrow from '../../images/footer_arrow.png';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className="footerContainer">
      <div>
        <h1 className="companyName">Sharper Image Financial Services</h1>
        <h2 className="copyright">
          Sharper Image Financial Services, LLC <span>&copy; {currentYear}</span>
        </h2>
        <div className="linksContainer">
          <a href="https://adviserinfo.sec.gov/firm/summary/330048" target='_blank' rel="noreferrer" className="brokerCheckLink">
            <img className='bcLogo' src={BrokerCheckLogo} alt="Link To BrokerCheck" />
          </a>
        </div>
        <p className="disclosureText">
          All content is for information purposes only. It is not intended to provide any tax or legal advice or provide the basis for any financial decisions. Nor is it intended to be a projection of current or future performance or indication of future results.
          Opinions expressed herein are solely those of Sharper Image Financial Services, LLC and our editorial staff. The information contained in this material has been derived from sources believed to be reliable but is not guaranteed as to accuracy and completeness and does not purport to be a complete analysis of the materials discussed. All information and ideas should be discussed in detail with your individual adviser prior to implementation. Advisory services are offered by Sharper Image Financial Services, LLC an Investment Advisor in the State of Colorado. Being registered as an investment adviser does not imply a certain level of skill or training. 
          The information contained herein should in no way be construed or interpreted as a solicitation to sell or offer to sell advisory services to any residents of any State other than the State of Colorado or where otherwise legally permitted. 
          Images and photographs are included for the sole purpose of visually enhancing the website. None of them are photographs of current or former Clients. They should not be construed as an endorsement or testimonial from any of the persons in the photograph.
          Purchases are subject to suitability. This requires a review of an investor’s objective, risk tolerance, and time horizons. Investing always involves risk and possible loss of capital.
          The inclusion of any link is not an endorsement of any products or services by Sharper Image Financial Services, LLC. All links have been provided only as a convenience. These include links to websites operated by other government agencies, nonprofit organizations and private businesses. When you use one of these links, you are no longer on this site and this Privacy Notice will not apply. When you link to another website, you are subject to the privacy of that new site. 
          When you follow a link to one of these sites neither Sharper Image Financial Services, LLC, nor any agency, officer, or employee of the Sharper Image Financial Services, LLC warrants the accuracy, reliability or timeliness of any information published by these external sites, nor endorses any content, viewpoints, products, or services linked from these systems, and cannot be held liable for any losses caused by reliance on the accuracy, reliability or timeliness of their information. Portions of such information may be incorrect or not current. Any person or entity that relies on any information obtained from these systems does so at her or his own risk.
          There are no warranties implied.
        </p>
      </div>
      <img
        id="scrollTopButton"
        className="scrollTopButton"
        src={Arrow}
        alt="Scroll to Top"
        title="Scroll to Top"
        onClick={handleScrollTop}
      />
    </footer>
  );
};

export default Footer;